<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex flex-col list-view-item bg-white rounded-lg shadow">
    <!-- image -->
    <div class="w-full p-4 lg:p-0 cursor-pointer flex justify-center">
      <!-- img :src="item.images[0].image" :alt="item.name" class="p-4" -->
      <!-- src="https://domf5oio6qrcr.cloudfront.net/medialibrary/7798/c5a743ce-27ab-4b0e-b3cf-167a7d29e45316207267658642.jpg" -->
      <img
        @click="navigate_to_detail_view"
        style="border-radius: 1.5rem !important"
        height="175px"
        class="hidden lg:inline-block lg:w-4/5 object-cover"
        :src="item.images[0].image"
      />
      <img
        @click="navigate_to_detail_view"
        style="border-radius: 1.5rem !important"
        height="250"
        width="250"
        class="lg:hidden object-cover"
        :src="item.images[0].image"
      />
    </div>

    <!-- product -->
    <div class="flex flex-col justify-center px-4 lg:p-0 lg:pl-5 w-full">
      <!-- <div class="text-lg opacity-75">{{ item.productType }}</div> -->
      <div
        class="text-lg md:text-lg lg:text-lg font-semibold cursor-pointer title-min-height"
        @click="navigate_to_detail_view"
      >
        {{ item.name }}
      </div>
      <div
        class="text-xl; md:text-xl lg:text-2xl mt-2 font-semibold text-primary my-3"
      >
        ${{ item.price | formatDecimals }}
      </div>
    </div>

    <!-- action -->
    <div class="flex flex-row justify-center items-end w-full">
      <div
        class="w-1/2 lg:w-48 flex justify-center"
        v-if="item.canBuy && item.isAvailable"
      >
        <div class="w-full justify-center">
          <div
            class="w-full flex flex-row increment-buttons rounded-bl rounded-big-screen py-1"
          >
            <div
              class="w-1/3 flex justify-center items-center text-2xl lg:text-4xl text-primary cursor-pointer"
              @click="decrease(quantity, item, item.inventory.requiredQuantity)"
            >
              <div>-</div>
            </div>
            <div class="w-1/3 flex justify-center items-center text-lg">
              <div>{{ quantity }}</div>
            </div>
            <div
              class="w-1/3 flex justify-center items-center text-2xl lg:text-4xl text-primary cursor-pointer"
              @click="increase(quantity, item)"
            >
              <div>+</div>
            </div>
          </div>
        </div>

        <span
          class="text-grey flex items-start justify-center mt-1"
          v-if="item.free_shipping"
        >
          <feather-icon icon="ShoppingCartIcon" svgClasses="w-4 h-4" />
          <span class="text-sm ml-2">Free Shipping</span>
        </span>
      </div>
      <slot name="action-buttons" :item2="item" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quantity: 1,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
  },
  watch: {
    "item.inventory": function () {
      if (
        this.item.inventory.requiredQuantity !== undefined &&
        this.item.inventory.requiredQuantity !== 0
      ) {
        this.quantity = this.item.inventory.requiredQuantity;
        this.item.selectedQuantity = this.item.inventory.requiredQuantity;
      } else {
        this.quantity = 1;
        this.item.inventory.requiredQuantity = 1;
        this.item.selectedQuantity = 1;
      }
    },
  },
  methods: {
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    increase(quantity, data) {
      this.quantity = quantity + 1;
      this.selectedChangeQuantity(this.quantity, data);
    },
    decrease(quantity, data, requiredQuantity) {
      if (quantity - 1 >= requiredQuantity) {
        this.quantity = quantity - 1;
        this.selectedChangeQuantity(this.quantity, data);
      }
    },
    selectedChangeQuantity(quantity, data) {
      this.$emit("quantityChange", {
        index: this.index,
        quantity: this.quantity,
      });

      if (quantity < data.inventory.requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg:
            "Minimum order quantity of " +
            data.name +
            " is " +
            data.inventory.requiredQuantity,
          id: data._id,
        });
      } else {
        this.errors.removeById(data._id);
        this.item.selectedQuantity = quantity;
      }
    },
    additemInCart(item) {
      this.$store.dispatch("eCommerce/additemInCart", item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push("/apps/eCommerce/checkout").catch(() => {})
        : this.additemInCart(item);
    },
    navigate_to_detail_view() {
      let name = "ClinicProductDetail";
      if (this.$store.state.AppActiveUser.userType === "doctorClinic") {
        name = "DoctorClinicProductDetail";
      }

      this.$router
        .push({
          name,
          params: {
            id: this.item._id,
          },
        })
        .catch(() => {});
    },
  },
  created() {
    if (
      this.item.inventory.requiredQuantity !== undefined &&
      this.item.inventory.requiredQuantity !== 0
    ) {
      this.quantity = this.item.inventory.requiredQuantity;
    } else {
      this.quantity = 1;
      this.item.inventory.requiredQuantity = 1;
    }
    this.item.selectedQuantity = this.quantity;
  },
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .increment-buttons {
    height: 42px;
    background: #3cc9b230;
    width: 100px;
  }
  // .item-name,
  // .item-description {
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  //   overflow: hidden;
  // }
  // .item-name {
  //   -webkit-line-clamp: 2;
  // }
  // .item-description {
  //   -webkit-line-clamp: 5;
  // }
  // .grid-view-img {
  //   max-width: 100%;
  //   max-height: 100%;
  //   width: auto;
  //   transition: .35s;
  // }
  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, .25);
  //   .grid-view-img {
  //     opacity: 0.9;
  //   }
  // }
}
@media (min-width: 768px) {
  .title-min-height {
    min-height: 80px;
  }
}
@media (min-width: 992px) {
  .rounded-big-screen {
    border-radius: 0 0 0 5px !important;
  }
  .title-min-height {
    min-height: 10px;
  }
}
</style>
